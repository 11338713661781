import { Section } from 'components/grid/wrappers/FlexWrapper';
import { Tabs } from 'components/ui/Tabs';
import { SearchInput } from 'components/ui/inputs/SearchInput';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { useSearchInput } from 'hooks/useSearchInput';
import { useSearchTabs } from 'hooks/useSearchTabs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { searchStores } from 'stores/search';
import { Container } from './styles';

export const SearchList = () => {
    const history = useHistory();

    const isLoading = useStore(searchStores.loading);

    const searchInput = useSearchInput();
    const { value, onClear } = searchInput;

    const searchTabs = useSearchTabs({
        searchText: value,
        onClear,
        isDropdown: false
    });
    const { activeTab } = searchTabs;

    const onSearchSubmit = () => {
        history.replace(`${AppRoute.Search}?query=${value}&type=${activeTab}`);
    };

    return (
        <Container>
            {isMobile && (
                <Section marginBottom="26px" marginTop="12px">
                    <SearchInput
                        disableAutocomplete
                        isLoading={isLoading}
                        placeholder="Search"
                        onSubmit={onSearchSubmit}
                        {...searchInput}
                    />
                </Section>
            )}

            <Tabs {...searchTabs} />
        </Container>
    );
};
