import { Tabs } from 'components/ui/Tabs';
import { SearchInput } from 'components/ui/inputs/SearchInput';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useSearchInput } from 'hooks/useSearchInput';
import { useSearchTabs } from 'hooks/useSearchTabs';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { searchStores } from 'stores/search';
import { Dropdown, SearchContainer } from './styles';

export const SearchDropdown = () => {
    const history = useHistory();
    const ref = useRef(null);

    const isLoading = useStore(searchStores.loading);
    const isPageSearch = history.location.pathname === AppRoute.Search;

    const searchInput = useSearchInput();
    const { value, isFocused, setIsFocused, onClear } = searchInput;
    const searchTabs = useSearchTabs({
        searchText: value,
        onClear,
        isDropdown: true
    });

    const onSearchSubmit = () => {
        history.push(`${AppRoute.Search}?query=${value}&type=${searchTabs.activeTab}`);
        setIsFocused(false);
    };

    useOutsideClick(ref, () => setIsFocused(false));

    return (
        <SearchContainer ref={ref}>
            <SearchInput
                disableAutocomplete
                isLoading={isLoading}
                placeholder="Search"
                onSubmit={onSearchSubmit}
                {...searchInput}
            />

            {isFocused && !isPageSearch && (
                <Dropdown>
                    <Tabs {...searchTabs} />
                </Dropdown>
            )}
        </SearchContainer>
    );
};
