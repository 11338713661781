import queryString from 'query-string';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface QueryProps {
    query?: string;
}

export const useSearchInput = () => {
    const history = useHistory();
    const { query } = queryString.parse(history.location.search) as QueryProps;

    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(query || '');

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const onClear = () => {
        setValue('');
        setIsFocused(false);
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    return { value, onChange, onClear, onFocus, isFocused, setIsFocused };
};
