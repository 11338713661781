import { SearchTabContent } from 'components/common/SearchTabContent';
import { SearchItem } from 'components/common/SearchTabContent/types';
import { SearchCategory, SearchItemType } from 'constants/enum';
import { AppRoute } from 'constants/routes';
import { useStore } from 'effector-react';
import { useDebounce } from 'hooks/useDebounce';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { categoriesEffects, categoriesStores } from 'stores/categories';
import { searchEvents, searchStores } from 'stores/search';
import { tagsEffects, tagsEvents, tagsStores } from 'stores/tags';
import { NoopType } from 'types';

const { getSearchItemsFx, setDefaultSearchItems } = searchEvents;
const { getTagsFx } = tagsEffects;
const { resetTags } = tagsEvents;

interface Props {
    searchText: string;
    onClear: NoopType;
    isDropdown?: boolean;
}

interface SearchPageQuery {
    query?: string;
    type?: SearchCategory;
}

export const useSearchTabs = ({ searchText, onClear, isDropdown = false }: Props) => {
    const history = useHistory();
    const { query, type } = queryString.parse(history.location.search) as SearchPageQuery;
    const isPageSearch = history.location.pathname === AppRoute.Search;

    const [activeTab, setActiveTab] = useState(type || SearchCategory.Topic);

    const searchItems = useStore(searchStores.searchItems);
    const categories = useStore(categoriesStores.$categories);
    const tags = useStore(tagsStores.$tags);

    const debouncedValue = useDebounce(searchText, 500);

    const getItems = (value: string) => {
        if (value.length > 0) {
            getSearchItemsFx({
                searchText: value
            });
            getTagsFx({
                search: value
            });
        } else {
            setDefaultSearchItems();
            resetTags();
        }
    };

    const onChange = (value: SearchCategory) => {
        setActiveTab(value);
    };

    useEffect(() => {
        categoriesEffects.getCategoriesFx();
    }, []);

    useEffect(() => {
        getItems(debouncedValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    useEffect(() => {
        if (isPageSearch) {
            history.replace(`${AppRoute.Search}?query=${debouncedValue}&type=${type || SearchCategory.Topic}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageSearch, debouncedValue]);

    useEffect(() => {
        if (isPageSearch) {
            history.replace(`${AppRoute.Search}?query=${query || ''}&type=${activeTab}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageSearch, activeTab]);

    const getTopicsTab = () => {
        const topics: SearchItem[] = searchItems
            .filter(item => item.type === SearchItemType.Topic)
            .map(item => ({
                title: item.title,
                entityId: item.entityId,
                thumbnailUrl: item.thumbnailUrl,
                type: item.type as SearchItemType,
                isCategory: false
            }));

        return {
            id: SearchCategory.Topic,
            title: `Topics (${topics.length})`,
            width: !isDropdown ? 'fit-content' : undefined,
            content: (
                <SearchTabContent
                    isDropdown={isDropdown}
                    items={topics}
                    searchValue={isDropdown ? searchText : query || ''}
                    type={SearchCategory.Topic}
                    onItemClick={onClear}
                />
            )
        };
    };

    const getCreatorsTab = () => {
        const creators = searchItems
            .filter(item => item.type === SearchItemType.User)
            .map(item => ({
                title: item.title,
                entityId: item.entityId,
                thumbnailUrl: item.thumbnailUrl,
                type: item.type as SearchItemType,
                isCategory: false
            }));

        return {
            id: SearchCategory.Creator,
            title: `Creators (${creators.length})`,
            width: !isDropdown ? 'fit-content' : undefined,
            content: (
                <SearchTabContent
                    isDropdown={isDropdown}
                    items={creators}
                    searchValue={isDropdown ? searchText : query || ''}
                    type={SearchCategory.Creator}
                    onItemClick={onClear}
                />
            )
        };
    };

    const getHashtagsTab = () => {
        const preparedTags =
            tags?.items?.map(tag => ({
                entityId: tag.tagId,
                title: `${tag.categoryInfo?.emojiCode ? `${tag.categoryInfo?.emojiCode} ` : ''}${tag.displayName}`,
                isCategory: tag.isCategory
            })) || [];
        const preparedCategories = categories?.items?.map(category => ({
            entityId: category.tagId,
            title: `${category.emojiCode} ${category.translation?.displayName}`,
            isCategory: true
        }));

        return {
            id: SearchCategory.Hashtag,
            title: `Hashtags (${preparedTags?.length > 0 ? preparedTags.length : preparedCategories?.length || 0})`,
            width: !isDropdown ? 'fit-content' : undefined,
            content: (
                <SearchTabContent
                    isDropdown={isDropdown}
                    isTabHashtag={!!(tags || categories)}
                    items={preparedTags?.length > 0 ? preparedTags : preparedCategories || []}
                    searchValue={isDropdown ? searchText : query || ''}
                    type={SearchCategory.Hashtag}
                    onItemClick={onClear}
                />
            )
        };
    };

    const topicsTab = getTopicsTab();
    const creatorsTab = getCreatorsTab();
    const hashtagsTab = getHashtagsTab();

    return { tabs: [topicsTab, creatorsTab, hashtagsTab], onChange, activeTab };
};
