export enum FollowPageType {
    Followers = 'followers',
    Followings = 'followings'
}

export enum NotificationsPageType {
    Notifications = 'notifications',
    Activity = 'activity'
}

export enum QuickSignalsTabsType {
    Signals = 'signals',
    Members = 'members'
}

export enum TeamPageType {
    Creators = 'creators',
    Videos = 'videos'
}

export enum SearchCategory {
    Topic = 'topic',
    Creator = 'creator',
    Hashtag = 'hashtag'
}

export enum SearchItemType {
    None,
    User,
    Video,
    Hashtag,
    Product,
    Topic,
    Team
}
